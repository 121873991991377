/* You can add global styles to this file, and also import other style files */

@media only screen and (min-width: 600px) {
    .container {
        padding-left: 45px;
    }
}


/* Color Variable */

$body-color: #717477;
$heading-color: #333333;
$white-color: #FFFFFF;
$page-title-bg: #0d3642;
$green-color: #35A4D4;

/* Font Variable */

$body-font: 'Titillium Web',
sans-serif;
$heading-font: 'Titillium Web',
sans-serif;
a {
    transition: 0s !important
}

p {
    transition: 0s !important
}

a,
a[href],
input[type='submit'],
input[type='image'],
label[for],
select,
button,
.pointer {
    cursor: pointer;
}

body {
    color: $body-color;
    line-height: 1.8;
    font-size: 16px;
}


/* Default CSS Start */

img {
    max-width: 100%;
}

a {
    transition: .5s;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

a:focus {
    text-decoration: none;
}

button {
    outline: 0 !important;
    box-shadow: none;
    border: none;
    background-color: transparent;
}

.form-control:focus {
    box-shadow: 0 0 0 0;
}

.d-table {
    width: 100%;
    height: 100%;
}

.d-table-cell {
    display: table-cell;
    vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $heading-color;
}

h3 {
    font-size: 22px;
    font-family: $heading-font;
    color: #0f2137;
}

.pt-100 {
    padding-top: 100px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pb-100 {
    padding-bottom: 100px;
}

.theme-button {
    z-index: 1;
    position: relative;
    .default-btn {
        font-size: 16px;
        font-weight: 500;
        color: $white-color;
        background: linear-gradient(0deg, #35A4D4 25%, #089BDB 100%);
        padding: 16px 40px;
        position: relative;
        display: inline-block;
        &::before {
            position: absolute;
            content: '';
            border: 1px solid $green-color;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: $white-color;
            z-index: -1;
            transition: .5s;
        }
        &:hover::before {
            top: -10px;
            right: -10px;
        }
    }
}

.section-title {
    margin: 0 auto 5px;
    span {
        font-size: 16px;
        color: #886a03;
        display: inline-block;
        margin-bottom: 10px;
        font-weight: 600;
        text-transform: uppercase;
    }
    h2 {
        font-size: 38px;
        font-weight: 700;
        margin-bottom: 15px;
    }
}


/* Default CSS End */


/** -------------- Home Page One CSS Start --------------**/


/* Header CSS Start */

.header-area {
    background: #D3EFFB;
    padding: 15px 0;
    .header-left-text {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                display: inline-block;
                color: #565659;
                font-size: 15px;
                font-family: $heading-font;
                font-weight: 500;
                margin-right: 20px;
                line-height: 1;
                a {
                    color: #565659;
                    &:hover {
                        color: $green-color;
                    }
                }
                i {
                    margin-right: 5px;
                    font-size: 16px;
                    top: 1px;
                    position: relative;
                }
            }
        }
    }
    .header-right {
        .header-social {
            ul {
                padding-left: 0;
                margin-bottom: 0;
                li {
                    display: inline-block;
                    line-height: 1;
                    margin-left: 5px;
                    a {
                        i {
                            width: 25px;
                            height: 25px;
                            line-height: 23px;
                            background: linear-gradient(0deg, #35A4D4 25%, #089BDB 100%);
                            color: $white-color;
                            font-size: 14px;
                            text-align: center;
                            display: inline-block;
                            transition: .5s;
                            border: 1px solid transparent;
                            &:hover {
                                background: $white-color;
                                color: $green-color;
                                border: 1px dashed $green-color;
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Header CSS End */


/* Navbar CSS Start */

.navbar-area {
    .main-nav {
        position: inherit;
        background: $white-color;
        padding-top: 18px !important;
        padding-bottom: 18px !important;
        border-bottom: 1px solid #cdcdcfd6;
        .navbar {
            padding: 0px 0px 0px 0px;
            margin-bottom: 0px;
            .navbar-brand {
                padding: 15px 40px 0px 0px;
                top: -2px;
                position: relative;
            }
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        color: #000000;
                        font-weight: 500;
                        text-transform: capitalize;
                        line-height: 1;
                        > {
                            i {
                                font-size: 12px;
                            }
                        }
                        &.active {
                            color: $green-color;
                        }
                    }
                    .dropdown-menu {
                        background: $white-color;
                        border: 0;
                        transform: scaleX(1);
                        right: 0;
                        border-radius: 15px;
                        .nav-item {
                            .nav-link {
                                border-bottom: 1px dashed $green-color;
                                padding: 10px 15px;
                                position: relative;
                                > {
                                    i {
                                        font-size: 12px;
                                        float: right;
                                    }
                                }
                                &:hover {
                                    color: $green-color;
                                    padding-left: 25px;
                                }
                                &:hover::before {
                                    width: 10px;
                                }
                                &:hover::after {
                                    width: 0px;
                                }
                                &::before {
                                    position: absolute;
                                    content: '';
                                    background: $green-color;
                                    width: 0;
                                    height: 1px;
                                    top: 50%;
                                    left: 0;
                                    right: 0;
                                    transition: .5s;
                                }
                                &.active {
                                    color: $green-color;
                                }
                                &:hover {
                                    color: $green-color;
                                }
                            }
                            .dropdown-menu {
                                left: -100%;
                                top: 0 !important;
                                opacity: 0;
                                visibility: hidden;
                                .nav-item {
                                    .nav-link {
                                        &:hover {
                                            color: $green-color;
                                            padding-left: 25px;
                                        }
                                        &:hover::before {
                                            width: 10px;
                                        }
                                        &:hover::after {
                                            width: 0px;
                                        }
                                        &::before {
                                            position: absolute;
                                            content: '';
                                            background: $green-color;
                                            width: 0;
                                            height: 1px;
                                            top: 50%;
                                            left: 0;
                                            right: 0;
                                            transition: .5s;
                                        }
                                        &.active {
                                            color: $green-color;
                                        }
                                    }
                                }
                            }
                            &:hover {
                                .dropdown-menu {
                                    opacity: 1 !important;
                                    visibility: visible;
                                    top: 0px !important;
                                }
                            }
                        }
                        &:last-child {
                            .nav-link {
                                border-bottom: 0px;
                            }
                        }
                    }
                    .dropdown-toggle {
                        &::after {
                            display: none;
                        }
                    }
                    &:hover {
                        .dropdown-menu {
                            transform: scaleX(1);
                        }
                    }
                }
            }
            .other-option {
                display: flex;
                .search-bar {
                    position: relative;
                    .search-icon {
                        width: 30px;
                        height: 30px;
                        border: 1px solid $green-color;
                        text-align: center;
                        line-height: 27px;
                        color: $green-color;
                        display: inline-block;
                        cursor: pointer;
                        transition: .5s;
                        &:hover {
                            background: $green-color;
                            color: $white-color;
                            border-radius: 20px;
                        }
                    }
                    .search-form {
                        position: absolute;
                        right: 0;
                        box-shadow: 0 0 15px rgba(0, 0, 0, .15);
                        padding: 15px;
                        background: $white-color;
                        top: 70px;
                        animation: fadeInDown 1s;
                        display: none;
                        z-index: 1;
                        .search-input {
                            height: 50px;
                            width: 230px;
                            padding-left: 20px;
                            border: 1px dashed $green-color;
                            font-size: 20px;
                            background: $white-color;
                            &:focus {
                                border: 1px solid $green-color;
                                outline: 0;
                            }
                        }
                         ::placeholder {
                            color: $body-color;
                            font-size: 18px;
                        }
                        button {
                            position: absolute;
                            top: 23px;
                            right: 25px;
                            border: 1px solid $green-color;
                            border-radius: 17px;
                            box-shadow: 0 0 10px rgba(0, 0, 0, .15);
                            color: $green-color;
                            width: 33px;
                            height: 33px;
                            line-height: 30px;
                        }
                    }
                }
                .sidebar-toggle {
                    .navbar-toggle {
                        width: 30px;
                        height: 30px;
                        border: 1px solid $green-color;
                        text-align: center;
                        line-height: 27px;
                        color: $green-color;
                        display: inline-block;
                        cursor: pointer;
                        transition: .5s;
                        padding: 0;
                        border-radius: 0;
                        margin-left: 10px;
                        &:hover {
                            background: $green-color;
                            color: $white-color;
                        }
                        &:focus {
                            outline: 0;
                            box-shadow: 0 0 0 0;
                        }
                    }
                }
            }
        }
    }
    &.sticky-top {
        animation: .5s ease-in-out fadeInDown;
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    }
}


/* Navbar CSS End */


/* Modal CSS Start */

.modal.fade:not(.in).left .modal-dialog {
    transform: translate3d(-25%, 0, 0);
}

.modal.fade:not(.in).right .modal-dialog {
    transform: translate3d(0%, 0, 0);
}

.modal.fade:not(.in).bottom .modal-dialog {
    transform: translate3d(0, 25%, 0);
}

.modal.right .modal-dialog {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
}

.right-modal {
    .modal {
        z-index: 9999999;
        transition: 1s;
        .modal-header {
            img {
                width: 120px;
                padding-top: 5px;
                padding-left: 20px;
            }
            button {
                span {
                    background: $white-color;
                    width: 50px;
                    height: 50px;
                    display: inline-block;
                    border-radius: 40px;
                    line-height: 50px;
                    transition: .5s;
                    box-shadow: 7px 5px 30px rgba(72, 73, 121, .15);
                    &:hover {
                        border-radius: 0px;
                    }
                }
            }
        }
        .modal-body {
            width: 500px;
            padding: 40px;
            background: $white-color;
            .modal-link {
                position: relative;
                margin-bottom: 30px;
                a {
                    display: inline-block;
                    box-shadow: 0 0 10px rgba(0, 0, 0, .10);
                }
                h3 {
                    a {
                        position: absolute;
                        bottom: -8px;
                        left: 0;
                        background: $white-color;
                        width: 100%;
                        height: 50px;
                        text-align: center;
                        padding-top: 15px;
                        color: #3f3f3f;
                        display: inline-block;
                        font-size: 18px;
                    }
                }
                &:hover h3 a {
                    background: $green-color;
                    color: $white-color;
                }
            }
        }
        &.fade {
            transition: .5s linear;
            transform: translateX(100%);
        }
        &.show {
            transform: translateX(0%);
        }
    }
}

.modal.right .modal-content {
    min-height: 100vh;
    border: 0;
}

.modal-body {
    padding: 0;
}

.modal-header .close {
    margin-right: 0;
}


/* Modal CSS End */


/* Banner CSS Start */

.main-banner {
    position: relative;
    height: 700px;
    .banner-text {
        max-width: 600px;
        h1 {
            font-size: 60px;
            font-weight: 700;
            font-family: $body-font;
            color: #080808;
            margin-bottom: 30px;
        }
        p {
            color: #808291;
            margin-bottom: 40px;
        }
        .theme-button {
            .default-btn {
                margin-right: 30px;
            }
            .video-btn {
                color: $heading-color;
                font-weight: 500;
                top: 6px;
                position: relative;
                &:hover {
                    color: $green-color;
                }
                i {
                    color: $green-color;
                    border: 1px solid $green-color;
                    width: 60px;
                    height: 60px;
                    display: inline-block;
                    text-align: center;
                    line-height: 60px;
                    transition: .5s;
                    margin-right: 10px;
                    font-size: 25px;
                    position: relative;
                }
                &:hover i {
                    border-radius: 50px;
                    box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.15);
                }
            }
        }
    }
    .banner-image {
        position: absolute;
        right: 100px;
        top: 100px;
        max-width: 450px;
        animation: translatey 8s infinite linear;
    }
}


/* Banner CSS End */


/* Video CSS Start */

.video-section {
    background: #0d3642;
    position: relative;
    padding-bottom: 300px;
    &::before {
        position: absolute;
        content: '';
        background-image: url(assets/img/video-shape.png);
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .video-text {
        position: relative;
        i {
            color: $white-color;
            background: $green-color;
            width: 80px;
            height: 80px;
            text-align: center;
            line-height: 85px;
            display: inline-block;
            font-size: 60px;
            position: relative;
            z-index: 0;
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                border: 3px solid rgba(255, 101, 80, 0.50);
                animation: pulse 2s ease-out infinite;
                left: 0;
                top: 0;
            }
        }
        p {
            position: absolute;
            left: 150px;
            top: 0;
            font-size: 30px;
            color: $white-color;
            line-height: 1.4;
        }
    }
}


/* Video CSS End */


/* Theory CSS Start */

.theory-section {
    margin-top: -200px;
    .theory-area {
        position: relative;
        background: $white-color;
        box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.04);
        .theory-text {
            padding: 60px;
            h2 {
                font-size: 35px;
                font-weight: 700;
                text-transform: capitalize;
                margin-bottom: 25px;
                line-height: 1.4;
            }
            p {
                color: $heading-color;
                margin-bottom: 30px;
            }
            .signature-box {
                img {
                    margin-bottom: 10px;
                }
                p {
                    margin-bottom: 0;
                    color: $heading-color;
                    font-weight: 500;
                }
                span {
                    font-size: 14px;
                    color: $heading-color;
                }
            }
        }
        .theory-img {
            position: absolute;
            right: 0;
            max-width: 350px;
            bottom: 0;
        }
    }
}


/* Theory CSS End */


/* About CSS Start */

.about-section {
    padding-top: 100px;
    background: $white-color;
    .about-image {
        img {
            border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
            box-shadow: 0 0 11px 0 rgba(0, 0, 0, .35);
            z-index: 1;
            animation: wrep 10s infinite linear;
            width: 100%;
            max-width: unset;
        }
    }
    .about-text {
        .section-title {
            margin: 0 auto 45px 0;
        }
        .about-us {
            margin-bottom: 20px;
            i {
                font-size: 40px;
                left: 20px;
                position: relative;
                top: 0;
                line-height: 1;
                margin-bottom: 10px;
                display: inline-block;
                color: #111111;
                z-index: 0;
                &::after {
                    position: absolute;
                    content: '';
                    width: 40px;
                    height: 40px;
                    top: 0;
                    left: -15px;
                    z-index: -1;
                }
                &.icon-one {
                    &::after {
                        background: rgba(132, 186, 63, 0.65);
                    }
                }
                &.icon-two {
                    &::after {
                        background: rgba(255, 124, 0, 0.64);
                        border-radius: 50px;
                    }
                }
                &.icon-three {
                    &::after {
                        background: rgba(255, 199, 10, 0.66);
                        transform: rotate(30deg);
                    }
                }
            }
            p {
                font-size: 14px;
                color: #808291;
                margin-bottom: 0;
            }
        }
        p {
            color: #808291;
            margin-bottom: 45px;
            max-width: 500px;
        }
    }
}


/* About CSS End */


/* Feature CSS Start */

.features-section {
    .features-card {
        text-align: center;
        box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.10);
        padding: 30px 87px;
        margin-bottom: 30px;
        transition: .5s;
        position: relative;
        background: $white-color;
        z-index: 1;
        &:hover {
            margin-top: -10px;
            box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.04);
        }
        &:hover p,
        &:hover h3 {
            color: $white-color;
        }
        i {
            font-size: 35px;
            width: 90px;
            height: 90px;
            display: inline-block;
            text-align: center;
            line-height: 90px;
            border-radius: 46% 54% 68% 32% / 55% 48% 52% 45%;
            margin-bottom: 25px;
            animation: icon-animation 5s ease-in-out infinite;
            border: 1px dashed;
        }
        h3 {
            font-weight: 600;
            margin-bottom: 10px;
            transition: .5s;
        }
        p {
            margin-bottom: 0;
            font-size: 16px;
            transition: .5s;
        }
        &.feature-one {
            i {
                color: #FFF;
                background: #FFF;
            }
            &:hover {
                background: #FFF;
            }
        }
        &.feature-two {
            i {
                color: #f0573c;
                background: #ffd7ca;
            }
            &:hover {
                background: #f0573c;
            }
        }
        &.feature-three {
            i {
                color: #3454d1;
                background: #e1e6f8;
            }
            &:hover {
                background: #3454d1;
            }
        }
        &.feature-four {
            i {
                color: #ffc70b;
                background: #fff9e6;
            }
            &:hover {
                background: #d1ad34;
                ;
            }
        }
        &.feature-five {
            i {
                color: #f26d56;
                background: #ede5fb;
            }
            &:hover {
                background: #f26d56;
            }
        }
        &.feature-six {
            i {
                color: #88a32a;
                background: #eff2e2;
            }
            &:hover {
                background: #88a32a;
            }
        }
    }
}


/* Feature CSS End */


/* Service CSS Start */

.service-section {
    position: relative;
    .service-img {
        position: absolute;
        background-image: url(assets/img/service-img.jpg);
        background-position: center center;
        background-size: cover;
        width: 55%;
        height: 100%;
        left: 0;
        top: 0;
    }
    .service-text {
        position: relative;
        background: #0d3642;
        margin-bottom: 50px;
        margin-top: 50px;
        padding: 100px;
        h2 {
            margin-bottom: 50px;
            color: $white-color;
            font-size: 35px;
            font-weight: 700;
            line-height: 1.4;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                padding: 15px 15px 15px 90px;
                background: $white-color;
                margin-bottom: 20px;
                position: relative;
                color: #808291;
                font-size: 18px;
                font-weight: 500;
                max-width: 400px;
                list-style: none;
                i {
                    background: $green-color;
                    color: $white-color;
                    text-align: center;
                    position: absolute;
                    line-height: 65px;
                    font-size: 25px;
                    height: 100%;
                    width: 60px;
                    left: 0;
                    top: 0;
                }
            }
        }
    }
}


/* Service CSS End */


/* Team CSS End */

.team-section {
    .section-title {
        max-width: 700px;
    }
    .team-card {
        margin-bottom: 80px;
        &:hover .team-text {
            background: $green-color;
        }
        &:hover .team-text h3 {
            color: $white-color;
        }
        &:hover .team-text p {
            color: $white-color;
        }
        &:hover .team-text .team-social {
            opacity: 1;
            transform: translateY(-15px);
        }
        .team-img {
            position: relative;
        }
        .team-text {
            background: $white-color;
            padding: 25px;
            box-shadow: 0 2px 10px rgba(12, 0, 46, 0.15);
            position: absolute;
            text-align: center;
            z-index: 1;
            width: 230px;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -50px;
            transition: .5s;
            transition: .5s;
            .team-social {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                opacity: 0;
                transition: .5s;
                ul {
                    padding-left: 0;
                    margin-bottom: 0;
                    li {
                        display: inline-block;
                        i {
                            background: $white-color;
                            color: $heading-color;
                            width: 25px;
                            height: 25px;
                            display: inline-block;
                            line-height: 25px;
                            text-align: center;
                            transition: .5s;
                            font-size: 16px;
                            &:hover {
                                color: $green-color;
                            }
                        }
                    }
                }
            }
            h3 {
                font-size: 18px;
                font-weight: 700;
                transition: .5s;
            }
            p {
                margin-bottom: 0;
                transition: .5s;
            }
        }
    }
}


/* Team CSS End */


/* Testimonial CSS Start */

.testimonial-section {
    background: #0d3642;
    .testimonial-slider {
        text-align: center;
        position: relative;
        z-index: 0;
        &::before {
            position: absolute;
            content: '';
            background: $white-color;
            width: 85%;
            height: 85%;
            left: 0;
            z-index: -1;
            margin: auto;
            right: 0;
            bottom: -20px;
            z-index: -1;
            border-radius: 30px;
        }
        img {
            max-width: 150px;
            width: unset;
            margin: 0 auto 40px;
            right: 0;
            left: 0;
            border: 3px solid $green-color;
            border-radius: 150px;
            padding: 5px;
            background: $white-color;
            box-shadow: 0 0 25px rgba(0, 0, 0, .15);
        }
        h3 {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 0;
        }
        span {
            color: $green-color;
            font-size: 16px;
            margin-bottom: 20px;
            display: inline-block;
        }
        p {
            padding: 0 105px;
        }
        .owl-prev,
        .owl-next {
            &:hover {
                background: none;
            }
            span {
                color: $green-color;
                font-size: 16px;
                margin-bottom: 20px;
                display: inline-block;
                width: 45px;
                height: 45px;
                border-radius: 50px;
                line-height: 40px;
                font-size: 30px;
                ;
                text-align: center;
                border: 1px solid $green-color !important;
                transition: .5s;
                &:hover {
                    background: $green-color;
                    color: $white-color !important;
                }
            }
        }
    }
    .testimonial-image {
        background-image: url(assets/img/testimonial-img.jpg);
        background-position: center center;
        background-size: cover;
        height: 100%;
    }
}


/* Testimonial CSS End */


/* Portfolio CSS Start */

.portfolio-section {
    .portfolio-item {
        cursor: pointer;
        margin-bottom: 30px;
        &:hover .portfolio-img .portfolio-text {
            bottom: 0;
        }
        .portfolio-img {
            position: relative;
            overflow: hidden;
            &::before {
                position: absolute;
                content: '';
                background: #000;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                opacity: .3;
            }
            img {
                width: 100%;
            }
            .portfolio-text {
                position: absolute;
                bottom: -68px;
                left: 0;
                right: 0;
                margin: auto;
                text-align: center;
                transition: .5s;
                background: rgba(0, 0, 0, .5);
                h3 {
                    color: $white-color;
                    font-weight: 700;
                    padding: 15px 0 15px;
                    margin-bottom: 0;
                }
                .portfolio-btn {
                    font-size: 14px;
                    font-weight: 500;
                    background: $green-color;
                    color: $white-color;
                    padding: 10px 20px;
                    display: inline-block;
                    margin-bottom: 20px;
                    border: 1px solid transparent;
                    i {
                        font-size: 10px;
                    }
                    &:hover {
                        border: 1px solid $white-color;
                        background: transparent;
                    }
                }
            }
        }
    }
}

.portfolio-section1 {
    font-family: "Titillium Web", sans-serif;
    .portfolio-item {
        cursor: pointer;
        &:hover .portfolio-img .portfolio-text {
            bottom: 0;
        }
        .portfolio-img {
            position: relative;
            overflow: hidden;
            &::before {
                position: absolute;
                content: '';
                background: #000;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                opacity: .3;
            }
            img {
                width: 100%;
                object-fit: cover;
                object-position: 50% 100;
            }
            .portfolio-text {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                text-align: left;
                transition: .5s;
                background: rgba(0, 0, 0, .5);
                h3 {
                    padding: 30px 350px 30px 45px;
                    color: $white-color;
                    font-weight: 400;
                    margin-bottom: 0;
                }
            }
        }
        .portfolio-text1 {
            position: absolute;
            height: 400px;
            top: 70px;
            left: 0;
            right: 0;
            margin: auto;
            text-align: left;
            transition: .5s;
            h3 {
                font-size: 32px;
                padding: 10px 350px 30px 45px;
                color: $white-color;
                line-height: 1.7;
                font-weight: 700;
                margin-bottom: 0;
            }
        }
        .portfolio-text2 {
            position: absolute;
            height: 400px;
            top: 70px;
            left: 0;
            right: 0;
            margin: auto;
            text-align: left;
            transition: .5s;
            h3 {
                font-size: 32px;
                padding: 10px 350px 30px 45px;
                color: $white-color;
                line-height: 1.7;
                font-weight: 700;
                margin-bottom: 0;
            }
        }
        @media only screen and (max-width: 1600px) {
            .portfolio-text {
                position: absolute;
                bottom: 0px;
                left: 0;
                right: 0;
                margin: auto;
                text-align: left;
                transition: .5s;
                background: rgba(0, 0, 0, .5);
                h3 {
                    padding: 30px 350px 30px 45px !important;
                    color: $white-color;
                    font-weight: 400;
                    margin-bottom: 0;
                }
            }
            .portfolio-text1 {
                position: absolute;
                height: 400px;
                top: 70px;
                left: 0;
                right: 0;
                margin: auto;
                text-align: left;
                transition: .5s;
                h3 {
                    font-size: 32px;
                    padding: 10px 350px 30px 45px !important;
                    color: $white-color;
                    line-height: 1.7;
                    font-weight: 700;
                    margin-bottom: 0;
                }
            }
        }
        @media only screen and (max-width: 600px) {
            .portfolio-text1 {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                text-align: left;
                transition: .5s;
                h3 {
                    padding: 15px 30px 15px 10px !important;
                    line-height: 1.2;
                    color: $white-color;
                    font-size: 27px;
                    font-weight: 540;
                    text-align: left;
                }
            }
            .portfolio-text2 {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                text-align: left;
                transition: .5s;
                h3 {
                    padding: 170px 30px 15px 10px !important;
                    line-height: 1.2;
                    color: $white-color;
                    font-size: 29px;
                    font-weight: 350;
                    text-align: left;
                }
            }
            .portfolio-text {
                transition: .5s;
                background: rgba(0, 0, 0, .5);
                h3 {
                    padding: 15px 30px 15px 10px !important;
                    bottom: 0;
                    text-align: left;
                    color: $white-color;
                    font-weight: 100;
                    font-size: 17px;
                    margin-bottom: 0;
                }
            }
        }
    }
}


/* Portfolio CSS End */


/* Progress CSS Start */

.progress-section {
    position: relative;
    .progress-image {
        background-image: url(assets/img/about.jpg);
        background-position: center center;
        background-size: cover;
        position: absolute;
        height: 100%;
        width: 60%;
        left: 0;
        top: 0;
    }
    .progress-text {
        position: relative;
        background: #0d3642;
        h2 {
            color: $white-color;
            margin-bottom: 40px;
            font-weight: 700;
            font-size: 35px;
            line-height: 1.4;
        }
        h4 {
            color: $white-color;
            margin-bottom: 40px;
            font-weight: 400;
            font-size: 25px;
            line-height: 1.4;
        }
        p {
            color: $white-color;
            margin-bottom: 80px;
        }
        .progress-inner {
            position: relative;
            margin-bottom: 60px;
            p {
                position: absolute;
                margin-bottom: 0;
                top: -35px;
                color: $white-color;
            }
            span {
                position: absolute;
                top: -35px;
                right: 0;
                color: $white-color;
            }
            .progress {
                height: 6px;
                border-radius: 0;
                .progress-bar {
                    background-color: $green-color;
                    height: 6px;
                }
            }
        }
         :last-child {
            margin-bottom: 0;
        }
    }
}


/* Progress CSS End */


/* Blog CSS Start */

.blog-section {
    .blog-card {
        box-shadow: 0px 2px 25px 0px rgba(12, 0, 46, .10);
        margin-bottom: 30px;
        background: $white-color;
        &:hover .blog-img img {
            transform: scale(1.1);
        }
        &:hover .blog-img .blog-date {
            left: 0;
        }
        .blog-img {
            position: relative;
            overflow: hidden;
            img {
                transition: .5s;
            }
            .blog-date {
                position: absolute;
                background: $green-color;
                width: 55px;
                text-align: center;
                top: 0;
                left: 25px;
                padding: 6px;
                transition: .5s;
                span {
                    color: $white-color;
                    display: inline-block;
                    line-height: 25px;
                }
            }
        }
        .blog-text {
            padding: 25px 25px 65px;
            h3 {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 15px;
                transition: .5s;
                line-height: 1.4;
                a {
                    color: #111111;
                }
            }
            .post-info {
                position: relative;
                img {
                    position: absolute;
                }
                a {
                    p {
                        position: absolute;
                        left: 50px;
                        top: 8px;
                        font-size: 14px;
                        color: #808291;
                        margin-bottom: 0;
                        transition: .5s;
                    }
                }
                .blog-btn {
                    position: absolute;
                    right: 0;
                    top: 9px;
                    font-size: 14px;
                    color: #808291;
                    i {
                        font-size: 12px
                    }
                }
            }
        }
        &:hover .blog-text a h3,
        &:hover .blog-text .post-info a p,
        &:hover .blog-text .post-info .blog-btn {
            color: $green-color;
        }
    }
}


/* Blog CSS End */


/* Contact CSS Start */

.contact-section {
    .contact-img {
        position: relative;
        background-image: url(assets/img/contact.jpg);
        background-position: center center;
        background-size: cover;
        img {
            position: absolute;
            max-width: 100%;
            height: 100%;
            display: none;
        }
    }
    .contact-form {
        box-shadow: 0px 2px 25px 0px rgba(12, 0, 46, .10);
        padding: 70px;
        background: $white-color;
        position: relative;
        .contact-text {
            padding-bottom: 40px;
            h3 {
                font-weight: 600;
                margin-bottom: 15px;
            }
            p {
                color: #646464;
                margin-bottom: 0;
            }
        }
        .form-group {
            .form-control {
                height: 50px;
                border: 1px solid $green-color;
                border-radius: 10px;
            }
            .message-field {
                border: 1px solid $green-color;
                width: 100%;
                border-radius: 10px;
                padding-left: 10px;
                padding-top: 10px;
                &:focus {
                    border: 1px solid $green-color;
                    outline: 0;
                }
                .list-unstyled {
                    margin-top: 0;
                }
            }
             ::placeholder {
                color: #464646;
            }
        }
        .default-btn {
            color: $white-color;
            border-radius: 5px;
            padding: 10px 20px;
            margin-top: 10px;
            z-index: 0;
            position: relative;
            transition: .5s;
            font-size: 16px;
            border: 1px solid $green-color;
            margin-bottom: 10px;
            &::before {
                position: absolute;
                content: '';
                background: $green-color;
                width: 100%;
                height: 100%;
                border-radius: 4px;
                top: 0;
                left: 0;
                transform: scale(1);
                transition: .5s;
                z-index: -1;
            }
            &:hover::before {
                transform: scale(0);
            }
            &:hover {
                color: $green-color;
                border: 1px solid $green-color;
            }
        }
        .contact-shape {
            position: absolute;
            right: 0;
            bottom: 0;
            animation: translatey 5s infinite linear;
        }
    }
}


/* Contact CSS End */


/* Newsletter CSS Start */

.newsletter-section {
    .newsletter-area {
        background: $green-color;
        padding: 70px;
        margin-bottom: -140px;
        z-index: 1;
        position: relative;
        text-align: center;
        h2 {
            font-size: 32px;
            font-weight: 700;
            color: $white-color;
            margin-bottom: 35px;
            line-height: 1;
        }
        .newsletter-form {
            position: relative;
            .form-control {
                border: 1px solid $white-color;
                padding: 20px 0 20px 20px;
                height: 50px;
                background: $green-color !important;
                color: $white-color;
                border-radius: 0;
                font-size: 18px;
            }
             ::placeholder {
                color: $white-color;
            }
            .default-btn {
                position: absolute;
                font-size: 16px;
                color: $white-color;
                height: 50px;
                padding: 0 20px;
                top: 0px;
                right: -1px;
                transition: .5s;
                border-left: 1px solid;
                background: $green-color;
                border: 1px solid;
                &:hover {
                    color: $white-color;
                    background: #000;
                }
            }
        }
        .subscribe-shape {
            position: absolute;
            right: 50px;
            bottom: 0;
            animation: translatey 5s infinite linear;
        }
    }
}


/* Newsletter CSS End */


/* Footer Section CSS Start */

.footer-area {
    background: #0d3642;
    padding-top: 55px;
    .footer-widget {
        .footer-logo {
            img {
                margin-bottom: 25px;
            }
        }
        p {
            color: $white-color;
            margin-bottom: 18px;
        }
        .footer-social {
            a {
                display: inline-block;
                margin-right: 5px;
                i {
                    color: $white-color;
                    font-size: 16px;
                    margin-right: 10px;
                    transition: .5s;
                    &:hover {
                        color: $white-color;
                    }
                }
            }
        }
        h3 {
            color: $white-color;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 40px;
            display: inline-block;
            position: relative;
            padding-bottom: 5px;
            &::before {
                position: absolute;
                content: '';
                background: #626a5b;
                width: 100%;
                height: 2px;
                bottom: -2px;
                left: 0;
            }
        }
        ul {
            padding-left: 0;
            line-height: 1;
            margin-bottom: 0;
            li {
                display: block;
                margin-bottom: 20px;
                transition: .5s;
                color: $white-color;
                a {
                    color: $white-color;
                    transition: .5s;
                    &:hover {
                        color: $green-color;
                        margin-left: 2px;
                    }
                    &:hover i {
                        color: $green-color;
                    }
                    i {
                        font-size: 12px;
                        top: -1px;
                        position: relative;
                    }
                }
                i {
                    font-size: 12px;
                    transition: .5s;
                }
            }
             :last-child {
                margin-bottom: 0;
            }
        }
    }
    .pl-75 {
        padding-left: 75px;
    }
    .copyright-area {
        border-top: 1px solid #4a6871;
        padding: 20px 0;
        margin-top: 95px;
        p {
            color: $white-color;
            margin-bottom: 0;
            text-align: left;
            a {
                color: $white-color;
                &:hover {
                    color: $green-color;
                }
            }
        }
        .lh-1 {
            line-height: 1;
        }
        ul {
            margin-bottom: 0;
            li {
                display: inline-block;
                margin-left: 25px;
                position: relative;
                &::before {
                    position: absolute;
                    content: '';
                    background: $white-color;
                    width: 5px;
                    height: 5px;
                    border-radius: 10px;
                    right: -15px;
                    top: 6px;
                }
                a {
                    color: $white-color;
                    &:hover {
                        color: $green-color;
                    }
                }
            }
             :nth-child(2) {
                &::before {
                    display: none;
                }
            }
        }
    }
}


/* Footer Section CSS End */


/** -------------- Home Page One CSS End --------------**/


/** -------------- Home Page Two CSS Start --------------**/


/* Header CSS Start */

.header-style-two {
    background: #071d24;
    .header-left-text {
        ul {
            li {
                color: #95999a;
                a {
                    color: #95999a;
                }
            }
        }
    }
}


/* Header CSS End */


/* Navbar CSS Start */

.navbar-style-two {
    .main-nav {
        background: #0d3642;
        border-bottom: 2px solid #26453c;
        .navbar {
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        color: #9d9eaa;
                        &:hover {
                            color: $green-color;
                        }
                    }
                    .dropdown-menu {
                        background: #0d3642;
                        box-shadow: 3px -5px 12px rgba(0, 0, 0, .50);
                        .nav-item {
                            .nav-link {
                                color: #9d9eaa;
                                &:hover {
                                    color: $green-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Navbar CSS End */


/* Banner CSS Start */

.banner-style-two {
    background: #0d3642;
    .banner-text {
        h1 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
        .default-btn {
            &::before {
                background: transparent;
                border: 1px solid $green-color;
            }
        }
        .video-btn {
            color: $green-color !important;
            &:hover {
                color: $white-color !important;
            }
            &:hover i {
                background: $white-color;
            }
        }
    }
    .banner-image {
        position: absolute;
        top: 100px;
    }
}


/* Banner CSS End */


/* About CSS Start */

.about-style-two {
    padding: 50px 0;
    position: relative;
    .about-image {
        background-image: url(assets/img/about/4.jpg);
        background-position: center center;
        background-size: cover;
        height: 100%;
        width: 50%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .about-text {
        position: relative;
        background: #0d3642;
        padding: 70px 80px;
        p {
            color: $white-color;
        }
        .section-title {
            span {
                color: #886a03;
            }
            h2 {
                color: $white-color;
            }
        }
        .about-us {
            i,
            p {
                color: $white-color;
            }
        }
    }
}


/* About CSS End */


/* Video CSS Start */

.video-style-two {
    background-image: url(assets/img/video-bg.jpg);
    background-position: center center;
    background-size: cover;
    position: relative;
    height: 100%;
    padding-top: 200px;
    padding-bottom: 200px;
    z-index: 0;
    &::before {
        position: absolute;
        content: '';
        background: #464544;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: .45;
        z-index: -1;
    }
    .video-text {
        a {
            margin-bottom: 38px;
            display: inline-block;
            i {
                color: $white-color;
                background: $green-color;
                width: 80px;
                height: 80px;
                text-align: center;
                line-height: 85px;
                display: inline-block;
                font-size: 50px;
                position: relative;
                z-index: 0;
                border-radius: 55% 45% 38% 62% / 52% 57% 43% 48%;
                &::after {
                    position: absolute;
                    content: '';
                    background: rgba(255, 101, 80, 0.50);
                    width: 100px;
                    height: 100px;
                    top: -10px;
                    left: -10px;
                    z-index: -1;
                    border-radius: 55% 45% 38% 62% / 52% 57% 43% 48%;
                    animation: wrep 5s infinite ease-in-out;
                }
            }
        }
        p {
            color: $white-color;
            margin-bottom: 0;
            font-size: 20px;
            font-weight: 500;
            text-transform: capitalize;
            line-height: 1;
        }
    }
}


/* Video CSS End */


/* Service CSS Start */

.service-style-two {
    .service-img {
        width: 50%;
    }
    .service-text {
        margin-top: 0;
        margin-bottom: 0;
    }
}


/* Service CSS End */


/* Progress CSS Start */

.progress-style-two {
    .progress-image {
        width: 50%;
        right: 0;
        left: inherit;
        background-image: url(assets/img/about.jpg);
    }
    .progress-text {
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
    }
}


/* Progress CSS End */


/* Price CSS Start */

.pricing-section {
    .pricing-card {
        padding: 45px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        position: relative;
        margin-bottom: 30px;
        background: $white-color;
        &::before {
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            content: '';
            height: 270px;
            position: absolute;
            background: $green-color;
            clip-path: polygon(0 0, 100% 0, 100% 65%, 0% 100%);
        }
        .price-header {
            position: relative;
            margin-bottom: 100px;
            h2 {
                color: $white-color;
                font-size: 32px;
                font-weight: 700;
                margin-bottom: 5px;
                line-height: 1;
            }
            h3 {
                color: $white-color;
                font-size: 32px;
                font-weight: 700;
                sup {
                    font-size: 15px;
                    font-weight: 400;
                    top: -14px;
                    position: relative;
                }
                span {
                    font-size: 15px;
                    font-weight: 400;
                }
            }
            i {
                left: 0;
                right: 0;
                top: 115px;
                z-index: 0;
                margin: auto;
                width: 100px;
                height: 100px;
                color: #000000;
                font-size: 60px;
                line-height: 90px;
                position: absolute;
                border-radius: 50px;
                display: inline-block;
                background: $white-color;
                border: 5px solid $green-color;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, .15);
            }
        }
        .pricing-feature {
            padding: 75px 0px 40px;
            ul {
                padding-left: 0;
                margin-bottom: 0;
                li {
                    color: #808291;
                    font-size: 18px;
                    margin-bottom: 5px;
                    list-style: none;
                    i {
                        color: $green-color;
                        margin-right: 10px;
                    }
                }
                 :last-child {
                    margin-bottom: 0;
                }
            }
        }
        &.price-card-two {
            i {
                z-index: 1;
            }
            &::before {
                height: 250px;
                clip-path: polygon(0 0, 100% 0, 100% 70%, 0 70%);
            }
            &::after {
                position: absolute;
                content: '';
                width: 50px;
                top: 150px;
                left: 0;
                right: 0;
                margin: auto;
                border-left: 90px solid transparent;
                border-right: 90px solid transparent;
                border-top: 100px solid $green-color;
                z-index: 0;
            }
        }
        &.price-card-three {
            &::before {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 65%);
            }
        }
    }
}


/* Price CSS End */


/**-------------- Home Page Two CSS End --------------**/


/**-------------- About Page CSS Start --------------**/


/* About Title CSS Start */

.about-title {
    height: 350px;
    background: $page-title-bg;
    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;
                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }
                a {
                    color: $white-color;
                    &:hover {
                        color: $green-color;
                    }
                }
                i {
                    font-size: 14px;
                }
                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}


/* About Title CSS End */


/* Theory CSS Start */

.theory-style-two {
    margin-top: 75px;
    margin-bottom: -130px;
    z-index: 1;
    position: relative;
    .theory-area {
        box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.14);
        .theory-img {
            bottom: 0;
        }
    }
}


/* Theory CSS End */


/* Video CSS Start */

.video-style-three {
    padding-top: 340px;
}


/* Video CSS End */


/**-------------- About Page CSS End --------------**/


/**-------------- Portfolio Page CSS Start --------------**/

.portfolio-title {
    height: 400px;
    background: $page-title-bg;
    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;
                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }
                a {
                    color: $white-color;
                    &:hover {
                        color: $green-color;
                    }
                }
                i {
                    font-size: 14px;
                }
                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}


/**-------------- Portfolio Page CSS End --------------**/


/**-------------- Blog Page CSS Start --------------**/

.blog-title {
    height: 400px;
    background: $page-title-bg;
    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;
                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }
                a {
                    color: $white-color;
                    &:hover {
                        color: $green-color;
                    }
                }
                i {
                    font-size: 14px;
                }
                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

.blog-title1 {
    width: 100%;
    height: 400px;
    background-image: url(assets/img/about.jpg);
    .title-text {
        h4 {
            color: $white-color;
            font-size: 28px;
            font-weight: 800;
            margin: 0 13px;
            text-align: left;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;
                text-align: left;
                font-weight: 800;
                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }
                a {
                    color: $white-color;
                    &:hover {
                        color: $green-color;
                    }
                }
                i {
                    font-size: 14px;
                }
                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .blog-title1 {
        width: 100%;
        height: 500px;
        .title-text {
            h4 {
                color: $white-color;
                font-size: 27px;
                font-weight: 600;
                margin: 0 13px;
                text-align: left;
            }
            ul {
                padding-left: 0;
                margin-bottom: 0;
                li {
                    color: $white-color;
                    display: inline-block;
                    position: relative;
                    margin: 0 13px;
                    text-align: left;
                    p {
                        font-size: 18px !important;
                        font-weight: 500;
                        text-align: left;
                    }
                    &::before {
                        position: absolute;
                        content: '';
                        width: 5px;
                        height: 5px;
                        background: $white-color;
                        top: 15px;
                        right: -15px;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .blog-title {
        width: 100%;
        height: 500px;
        .title-text {
            ul {
                padding-left: 0;
                margin-bottom: 0;
                li {
                    color: $white-color;
                    display: inline-block;
                    position: relative;
                    margin: 0 13px;
                    text-align: left;
                    p {
                        font-size: 18px !important;
                        font-weight: 500;
                        text-align: center;
                    }
                    &::before {
                        position: absolute;
                        content: '';
                        width: 5px;
                        height: 5px;
                        background: $white-color;
                        top: 15px;
                        right: -15px;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}


/**-------------- Blog Page CSS End --------------**/


/**-------------- Blog Details Page CSS Start --------------**/

.blog-details-area {
    .blog-description {
        .article-image {
            margin-bottom: 35px;
            position: relative;
            .blog-date {
                position: absolute;
                background: $green-color;
                width: 55px;
                text-align: center;
                top: 25px;
                left: 25px;
                padding: 6px;
                span {
                    color: $white-color;
                    display: inline-block;
                    line-height: 25px;
                }
            }
        }
        .article-info {
            margin-bottom: 25px;
            position: relative;
            ul {
                margin: 0;
                padding-left: 0;
                li {
                    font-size: 14px;
                    color: $green-color;
                    display: inline-block;
                    margin-right: 15px;
                    font-weight: 700;
                    i {
                        margin-right: 5px;
                        display: none;
                    }
                }
            }
        }
        .article-title {
            font-size: 32px;
            color: #111111;
            font-weight: 600;
            margin-bottom: 25px;
        }
        p {
            font-family: $heading-font;
            margin-bottom: 10px;
        }
        .article-bottom-img {
            margin-bottom: 20px;
            margin-top: 10px;
        }
        .article-meta {
            position: relative;
            padding-top: 15px;
            margin-bottom: 30px;
            &::before {
                position: absolute;
                content: '';
                background: #ebebeb;
                width: 50%;
                height: 1px;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
            }
            ul {
                padding-left: 0;
                margin-bottom: 0;
                li {
                    display: inline-block;
                    font-size: 14px;
                    margin-right: 15px;
                    color: #808291;
                    i {
                        margin-right: 5px;
                    }
                }
            }
        }
        .blog-nav {
            display: flex;
            margin-bottom: 40px;
            background: #ddd;
            padding: 20px;
            margin-top: 20px;
            .prev-btn,
            .next-btn {
                width: 50%;
                a {
                    color: #3f3f3f;
                    border: 1px solid $green-color;
                    border-radius: 5px;
                    padding: 8px 20px;
                    font-family: $heading-font;
                    display: inline-block;
                    background: $white-color;
                    &:hover {
                        background: $green-color;
                        color: $white-color;
                    }
                }
            }
        }
        .blog-comment {
            box-shadow: 0 0 10px rgba(0, 0, 0, .15);
            padding: 30px;
            margin-bottom: 30px;
            h3 {
                font-size: 22px;
                font-weight: 600;
                color: #333333;
                margin-bottom: 25px;
            }
            .form-control {
                height: 60px;
                padding-left: 20px;
                border: 1px solid $green-color;
                width: 100%;
                border-radius: 0;
                background: #fcf6f6;
                margin-bottom: 30px;
            }
            .message-field {
                padding-top: 20px;
                padding-left: 20px;
                border: 1px solid $green-color;
                width: 100%;
                background: #fcf6f6;
                &:focus {
                    outline: 0;
                }
            }
             ::placeholder {
                font-size: 14px;
                color: #a07373;
                font-family: $heading-font;
            }
            .default-btn {
                font-size: 16px;
                font-family: $heading-font;
                font-weight: 600;
                background: $green-color;
                color: $white-color;
                padding: 15px 40px;
                margin-top: 10px;
                border: 1px solid transparent;
                transition: .5s;
                &:hover {
                    background: $white-color;
                    border: 1px dashed $green-color;
                    color: $green-color;
                }
            }
        }
    }
    .blog-search {
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        padding: 20px;
        margin-bottom: 30px;
        form {
            position: relative;
            input {
                border: 1px solid $green-color;
                padding: 10px 20px;
                width: 100%;
                &:focus {
                    outline: 0;
                }
            }
             ::placeholder {
                font-size: 16px;
                font-family: $heading-font;
                color: #333333;
            }
            button {
                position: absolute;
                background: $green-color;
                text-align: center;
                right: 0;
                top: 0;
                width: 50px;
                height: 100%;
                font-size: 18px;
                transition: .5S;
                &:hover {
                    background: $heading-color;
                }
                i {
                    color: $white-color;
                }
            }
        }
    }
    .recent-blog {
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        margin-bottom: 30px;
        h3 {
            font-weight: 600;
            margin-bottom: 20px;
        }
        .recent-post {
            position: relative;
            margin-bottom: 20px;
            border-bottom: 1px dashed #dddddd;
            padding-bottom: 20px;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 90px;
                height: 90px;
                border-radius: 5px;
            }
            h3 {
                margin-bottom: 0;
                a {
                    color: $heading-color;
                    font-size: 14px;
                    padding-left: 110px;
                    margin-bottom: 10px;
                    padding-top: 8px;
                    display: inline-block;
                    line-height: 18px;
                    &:hover {
                        color: $green-color;
                    }
                }
            }
            ul {
                padding-left: 110px;
                margin-bottom: 0;
                li {
                    display: inline-block;
                    color: $green-color;
                    font-size: 14px;
                    font-family: $heading-font;
                    margin-right: 10px;
                    i {
                        font-size: 14p
                    }
                }
            }
            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
    }
    .blog-category {
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        margin-bottom: 30px;
        h3 {
            font-weight: 600;
            margin-bottom: 20px;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                line-height: 1;
                list-style: none;
                a {
                    padding: 15px 20px;
                    display: block;
                    border-left: 2px solid $green-color;
                    position: relative;
                    border-bottom: 1px dashed #dddddd;
                    font-size: 18px;
                    font-weight: 600;
                    color: $heading-color;
                    margin-bottom: 5px;
                    i {
                        position: absolute;
                        top: 12px;
                        right: 0px;
                        font-size: 25px;
                    }
                    &:hover {
                        background: $green-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
    .photo-gallery {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        margin-bottom: 30px;
        h3 {
            font-weight: 600;
            margin-bottom: 20px;
            padding-left: 20px;
            padding-top: 20px;
        }
        a {
            img {
                padding-left: 20px;
                margin-bottom: 20px;
            }
        }
    }
    .tags {
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        margin-bottom: 30px;
        h3 {
            font-weight: 600;
            margin-bottom: 20px;
        }
        a {
            background: #e5e5e5;
            color: #333333;
            padding: 5px 15px;
            margin-bottom: 10px;
            display: inline-block;
            border-radius: 10px;
            border: 1px dashed $heading-color;
            text-align: center;
            margin-right: 10px;
            font-size: 13px;
            font-weight: 600;
            &:hover {
                background: $green-color;
                color: $white-color;
            }
        }
    }
}


/**-------------- Blog Details Page CSS End --------------**/


/**-------------- Contact Page CSS Start --------------**/


/* Contact Title Start */

.contact-title {
    background: $page-title-bg;
    padding-top: 100px;
    padding-bottom: 200px;
    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;
                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }
                a {
                    color: $white-color;
                    &:hover {
                        color: $green-color;
                    }
                }
                i {
                    font-size: 14px;
                }
                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}


/* Contact Title Start */


/* Contact Section Start */

.contact-area {
    .contact-box {
        margin-top: -100px;
        background: $white-color;
        padding: 60px 45px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        .contact-card {
            border: 1px solid $green-color;
            position: relative;
            padding: 20px;
            i {
                width: 50px;
                height: 50px;
                border-radius: 50px;
                border: 1px solid $green-color;
                display: inline-block;
                color: $green-color;
                text-align: center;
                line-height: 50px;
                font-size: 20px;
                position: absolute;
                top: 37px;
                left: 25px;
            }
            h3 {
                padding-left: 80px;
                font-weight: 700;
            }
            ul {
                padding-left: 0;
                margin-bottom: 0;
                padding-left: 80px;
                li {
                    color: #4a4a4a;
                    font-size: 14px;
                    list-style: none;
                }
            }
        }
    }
}

.map {
    line-height: 1;
    height: 500px;
    margin-bottom: -120px;
    iframe {
        width: 100%;
        height: 500px;
        border: 0
    }
}


/* Contact Section End */


/* Footer Area Start */

.footer-style-three {
    clip-path: none;
}


/* Footer Area End */


/**-------------- Contact Page CSS End --------------**/


/**-------------- Service Page CSS Start --------------**/

.service-title {
    height: 400px;
    background: $page-title-bg;
    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;
                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }
                a {
                    color: $white-color;
                    &:hover {
                        color: $green-color;
                    }
                }
                i {
                    font-size: 14px;
                }
                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

.service-style-three {
    .service-card {
        text-align: center;
        box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.10);
        padding: 30px 20px;
        margin-bottom: 30px;
        transition: .5s;
        position: relative;
        border-bottom: 2px solid $body-color;
        &:hover {
            margin-top: -10px;
        }
        &:hover i {
            animation: none;
            border: 1px dashed $green-color;
            color: $green-color;
        }
        i {
            font-size: 35px;
            width: 90px;
            height: 90px;
            display: inline-block;
            text-align: center;
            line-height: 90px;
            border-radius: 46% 54% 68% 32% / 55% 48% 52% 45%;
            margin-bottom: 25px;
            animation: icon-animation 5s ease-in-out infinite;
            border: 1px dashed;
            transition: .5s;
        }
        h3 {
            font-weight: 600;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 0;
            font-size: 16px;
            color: #0f2137;
        }
    }
}


/**-------------- Serice Page CSS End --------------**/


/**-------------- Portfolio Page Two CSS Start --------------**/

.portfolio-style-two {
    .portfolio-menu {
        margin-bottom: 50px;
        ul {
            li {
                cursor: pointer;
                padding: 10px 20px;
                list-style-type: none;
                display: inline-block;
                border: 1px solid $green-color;
                transition: .5s;
                &:hover {
                    background: $green-color;
                    color: $white-color;
                }
            }
            .active {
                color: $white-color;
                background: $green-color;
            }
        }
    }
}

#Container .mix {
    display: none;
}


/**-------------- Portfolio Page Two CSS End --------------**/


/**-------------- Pricing Page CSS Start --------------**/

.pricing-title {
    height: 400px;
    background: $page-title-bg;
    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;
                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }
                a {
                    color: $white-color;
                    &:hover {
                        color: $green-color;
                    }
                }
                i {
                    font-size: 14px;
                }
                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}


/**-------------- Pricing Page CSS End --------------**/


/**-------------- 404 Page CSS Start --------------**/

.error-title {
    height: 400px;
    background: $page-title-bg;
    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;
                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }
                a {
                    color: $white-color;
                    &:hover {
                        color: $green-color;
                    }
                }
                i {
                    font-size: 14px;
                }
                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

.error-section {
    .error-img {
        margin-bottom: 50px;
        img {
            width: 100%;
        }
    }
}


/**-------------- 404 Page CSS End --------------**/


/**-------------- Team Page CSS Start --------------**/

.team-title {
    height: 400px;
    background: $page-title-bg;
    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;
                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }
                a {
                    color: $white-color;
                    &:hover {
                        color: $green-color;
                    }
                }
                i {
                    font-size: 14px;
                }
                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

.footer-style-two {
    padding-top: 100px;
    clip-path: none;
}


/**-------------- Team Page CSS End --------------**/


/**-------------- Team Page Two CSS Start --------------**/

.team-style-two {
    .team-card {
        margin-bottom: 30px;
        .team-img {
            position: relative;
            &:hover .team-text {
                background: rgba(0, 0, 0, .5);
                transform: scale(1);
            }
            img {
                width: 100%;
            }
        }
        .team-text {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            margin: auto;
            top: 0;
            bottom: 0;
            background: rgba(0, 0, 0, .5);
            color: $white-color;
            transform: scale(0);
            h3 {
                font-size: 25px;
                color: $white-color;
            }
            &:hover {
                background: rgba(0, 0, 0, .5);
            }
        }
    }
}


/**-------------- Team Page Two CSS End --------------**/


/**-------------- Service Page Details CSS Start --------------**/

.service-style-four {
    .service-details-area {
        .service-details-img {
            margin-bottom: 40px;
            img {
                width: 100%;
            }
        }
        h3 {
            position: relative;
            padding-left: 0;
            margin-bottom: 15px;
            font-size: 30px;
        }
        p {
            margin-bottom: 15px;
        }
        .mb-35 {
            margin-bottom: 35px;
        }
        .service-btm-img {
            margin: 10px 0 20px;
        }
    }
    .blog-search {
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        padding: 20px;
        margin-bottom: 30px;
        form {
            position: relative;
            input {
                border: 1px solid $green-color;
                padding: 10px 20px;
                width: 100%;
                &:focus {
                    outline: 0;
                }
            }
             ::placeholder {
                font-size: 16px;
                font-family: $heading-font;
                color: #333333;
            }
            button {
                position: absolute;
                background: $green-color;
                text-align: center;
                right: 0;
                top: 0;
                width: 50px;
                height: 100%;
                font-size: 18px;
                transition: .5S;
                &:hover {
                    background: $heading-color;
                }
                i {
                    color: $white-color;
                }
            }
        }
    }
    .service-category {
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        margin-bottom: 30px;
        h3 {
            font-weight: 600;
            margin-bottom: 20px;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                line-height: 1;
                list-style: none;
                a {
                    padding: 15px 20px;
                    display: block;
                    border-left: 2px solid $green-color;
                    position: relative;
                    border-bottom: 1px dashed #dddddd;
                    font-size: 18px;
                    font-weight: 600;
                    color: $heading-color;
                    margin-bottom: 5px;
                    i {
                        position: absolute;
                        top: 12px;
                        right: 0px;
                        font-size: 25px;
                    }
                    &:hover {
                        background: $green-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
}


/**-------------- Service Page Details CSS End --------------**/


/**-------------- FAQ Page CSS Start --------------**/

.faq-title {
    height: 400px;
    background: $page-title-bg;
    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;
                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }
                a {
                    color: $white-color;
                    &:hover {
                        color: $green-color;
                    }
                }
                i {
                    font-size: 14px;
                }
                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

.faq-area {
    h2 {
        margin-left: 20px;
        padding-right: 20px;
        padding-bottom: 15px;
        margin-bottom: 50px;
        font-size: 32px;
        font-weight: 600;
        border-bottom: 2px dashed #886a03;
    }
    .topic {
        padding: 20px;
        padding-top: 0px;
        padding-bottom: 0px;
        h3 {
            color: $heading-color;
        }
    }
    .open {
        cursor: pointer;
        display: block;
        padding: 0px;
        height: 60px;
        &:hover {
            opacity: 0.7;
        }
    }
    .expanded {
        background-color: #f5f5f5;
        transition: all .3s ease-in-out;
    }
    .question {
        padding-top: 20px;
        padding-right: 40px;
        padding-bottom: 20px;
        font-size: 18px;
        font-weight: 500;
        color: #526ee4;
        padding-left: 40px;
    }
    .answer {
        font-size: 16px;
        line-height: 26px;
        display: none;
        margin-bottom: 30px;
        text-align: justify;
        padding-left: 40px;
        padding-right: 20px;
        padding-bottom: 20px;
    }
    .faq-t {
        transform: rotate(-45deg);
        display: inline-block;
        position: relative;
        top: -55px;
        left: 5px;
        width: 10px;
        height: 10px;
        background: transparent;
        border-left: 2px solid #ccc;
        border-bottom: 2px solid #ccc;
        transition: all .3s ease-in-out;
    }
    .faq-o {
        top: -50px;
        transform: rotate(-224deg);
    }
}


/**-------------- FAQ Page CSS End --------------**/


/**-------------- Privacy Page CSS Start --------------**/

.privacy-title {
    height: 400px;
    background: $page-title-bg;
    .title-text {
        h2 {
            color: $white-color;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                font-size: 18px;
                color: $white-color;
                display: inline-block;
                position: relative;
                margin: 0 13px;
                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $white-color;
                    top: 15px;
                    right: -15px;
                    border-radius: 10px;
                }
                a {
                    color: $white-color;
                    &:hover {
                        color: $green-color;
                    }
                }
                i {
                    font-size: 14px;
                }
                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

.privacy-section {
    padding-bottom: 60px;
    .privacy-text {
        margin-bottom: 15px;
        h3 {
            font-size: 20px;
            font-family: "Titillium Web", sans-serif;
            color: #0f2137;
            text-transform: capitalize;
            margin-bottom: 10px;
            display: inline-block;
        }
        p {
            margin-bottom: 0;
        }
    }
    ul {
        li {
            margin-top: 10px;
            list-style: circle;
            color: #111111;
        }
    }
}


/**-------------- Privacy Page CSS End --------------**/


/**-------------- Back to Top CSS Start --------------**/


/**-------------- Back to Top Page CSS End --------------**/


/**-------------- Pre Loader CSS Start --------------**/

.loader-content {
    background: $white-color;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 99999999;
    left: 0;
    top: 0;
    text-align: center;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #35A4D4;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}


/**-------------- Pre Loader CSS End --------------**/


/* Magnific Popup */

.mfp-fade.mfp-bg {
    opacity: 0;
    transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}


/* Animation CSS */

@keyframes icon-animation {
    0% {
        border-radius: 46% 54% 68% 32% / 55% 48% 52% 45%;
    }
    50% {
        border-radius: 66% 34% 41% 59% / 51% 34% 66% 49%;
    }
    100% {
        border-radius: 46% 54% 68% 32% / 55% 48% 52% 45%;
    }
}

@keyframes wrep {
    0% {
        border-radius: 55% 45% 38% 62% / 52% 57% 43% 48%;
    }
    35% {
        border-radius: 67% 33% 39% 61% / 48% 63% 37% 52%;
    }
    70% {
        border-radius: 40% 60% 62% 38% / 32% 41% 59% 68%;
    }
    100% {
        border-radius: 55% 45% 38% 62% / 52% 57% 43% 48%;
    }
}

@keyframes translatex {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(20px);
    }
    10% {
        transform: translateX(0px);
    }
}

@keyframes translatey {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-15px);
    }
    10% {
        transform: translateY(0px);
    }
}


/* play button animation */

@keyframes pulse {
    from {
        transform: scale(1);
        filter: opacity(0.9);
    }
    to {
        transform: scale(2);
        filter: opacity(0);
    }
}

.portfolio-text1,
.portfolio-text2,
.portfolio-text {
    margin: 0px 20px;
}

@media screen and (min-width: 992px) {
    .portfolio-text1,
    .portfolio-text2,
    .portfolio-text {
        margin: 0px auto;
        max-width: 866px;
    }
}

@media screen and (min-width: 1200px) {
    .portfolio-text1,
    .portfolio-text2,
    .portfolio-text {
        margin: 0px auto;
        max-width: 1440px;
    }
}

@media screen and (min-width: 1400px) {
    .portfolio-text1,
    .portfolio-text2,
    .portfolio-text {
        max-width: 2000px;
    }
}

//////////////// 
.portfolio-text1 h3,
.portfolio-text2 h3,
.portfolio-text h3 {
    margin: 0px 20px;
}

@media screen and (min-width: 992px) {
    .portfolio-text1 h3,
    .portfolio-text2 h3,
    .portfolio-text h3 {
        margin: 0px auto;
        max-width: 866px;
    }
}

@media screen and (min-width: 1200px) {
    .portfolio-text1 h3,
    .portfolio-text2 h3,
    .portfolio-text h3 {
        margin: 0px auto;
        max-width: 1140px;
    }
}

@media screen and (min-width: 1400px) {
    .portfolio-text1 h3,
    .portfolio-text2 h3,
    .portfolio-text h3 {
        max-width: 1320px;
    }
}